import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import Store from './store/reducer';
import config from './config';
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./backendActions/authActions";
import toast, { Toaster } from 'react-hot-toast';



const app = (
    
    <Provider store={Store}>
        {/* <BrowserRouter basename={'/young'}> */}
        <BrowserRouter basename={'/'}>
          
            <App />
        {/* </BrowserRouter> */}
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));


serviceWorker.unregister();
